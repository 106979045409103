import { useInView } from 'react-intersection-observer'
import { LayoutClasses } from '../LayoutClasses';
import { ImageCopyright } from './ImageCopyright';

export function Quote({ author, author_meta, quote, image, image_title, image_position, font_size, layout_classes, type }) {

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div ref={ref} className={`quote -image-${image_position} -font-${font_size} ${!image ? '-no-image' : ''} ${inView ? '-in-view' : ''}`}>
      {image && (
        <div className="quote__image">
          <img src={image} alt={author} title={image_title}/>
        </div>
      )}
      <div className="quote__content">
        <blockquote>
          {quote}
        </blockquote>
        <div className="quote__name">{author}</div>
        <div className="quote__meta">{author_meta}</div>
        {image_title && (<ImageCopyright image_copyright={image_title}/>)}
      </div>
    </div>
  </LayoutClasses>
}
import { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer'
import { LayoutClasses } from '../LayoutClasses';

export function VideoPlayer({ video, placeholder, layout_classes, type, embedd_code, description }) {

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const html5Video = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const onVideoIsPlayed = () => {
    setIsPlaying(true);
  }

  const onVideoIsPaused = () => {
    setIsPlaying(false);
  }

  const onPlayButtonClicked = () => {
    html5Video.current.play();
  }

  useEffect(() => {
    html5Video.current.addEventListener('play', onVideoIsPlayed)
    html5Video.current.addEventListener('pause', onVideoIsPaused)
  }, [html5Video])

  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div ref={ref} className={`video-player ${inView ? '-in-view' : ''}`}>
      <video ref={html5Video} className="video-player__video" poster={placeholder} src={video} controls={isPlaying ? 'controls' : ''}>
        Your browser does not support the video tag.
      </video>
      <div className={`video-player__overlay ${isPlaying ? '-playing' : ''}`}>
        <div className="video-player__overlay__play" onClick={onPlayButtonClicked}></div>
      </div>
    </div>
    {description && (<div className={`video-player__description`}>
      {description}
    </div>)}
  </LayoutClasses>
}
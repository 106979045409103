import { useEffect, useRef, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { renderHtml } from '../../utils/RenderHtml';
import { useInView } from 'react-intersection-observer'
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

import { LayoutClasses } from '../LayoutClasses';

gsap.registerPlugin(ScrollToPlugin);


export function Text({ content, type, layout_classes }) {

  let history = useHistory();

  const ref = useRef();

  const [inViewRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef],
  );

  const addEventlistenersForLinks = (links) => {
    links.forEach((item) => {
      item.addEventListener('click', handleLinkCLick);
    });
  }
  const removeEventlistenersForLinks = (links) => {
    links.forEach((item) => {
      item.removeEventListener('click', handleLinkCLick);
    });
  }

  const handleLinkCLick = (e) => {
    const url = new URL(e.currentTarget.getAttribute('href'))
    if (url.protocol === 't3:') {
      e.preventDefault();
      if (url.hash) {
        gsap.to(window, 2, { scrollTo: { y: url.hash, offsetY: 90 }, ease: 'power2.inOut' });
      }
      return false
    }
    if (window.location.origin === url.origin) {
      e.preventDefault();
      history.push(url.pathname);
    }
  }

  useEffect(() => {
    const links = ref.current.querySelectorAll('a')
    addEventlistenersForLinks(links)

    return function cleanup() {
      removeEventlistenersForLinks(links)
    }
  }, []);

  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div ref={setRefs} className={`bwiapi_text ${inView ? '-in-view' : ''}`}>
      {renderHtml(content)}
    </div>
  </LayoutClasses>
}
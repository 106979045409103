import IntersectionObjserver from 'intersection-observer';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import React, { Fragment, useState } from 'react';

import { Navigation } from './components/Navigation';
import { KVSlider } from './components/KeyvisualSlider';
import { Footer } from './components/Footer';
import { useQuery } from 'react-fetching-library';
import { Page } from './components/Page';
import { ContentControl } from './components/ContentControl';
import { ToOverview } from './components/ToOverview';

import 'elementsfrompoint-polyfill';
import { CookieConsent } from './components/CookieConsent';
import { Datenschutzerklaerung } from './components/Datenschutzerklaerung';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'


// DUMMY
const io = IntersectionObjserver;
IntersectionObserver.prototype.POLL_INTERVAL = 100;

const fetchSitemap = {
  method: 'GET',
  endpoint: '/',
  config: {
    type: 'sitemap',
  },
};

const socialChannels = [
  {
    icon: '/assets/icons/icon-facebook.svg',
    name: 'Facebook',
    url: 'https://www.facebook.com/BWIITfuerDeutschland',
  },
  {
    icon: '/assets/icons/icon-insta.svg',
    name: 'Instagram',
    url: 'https://www.instagram.com/bwikarriere/',
  },
  {
    icon: '/assets/icons/icon-twitter.svg',
    name: 'Twitter',
    url: 'https://twitter.com/BWI_IT',
  },
  {
    icon: '/assets/icons/icon-linkedin.svg',
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/bwi-gmbh/',
  },
  {
    icon: '/assets/icons/icon-xing.svg',
    name: 'Xing',
    url: 'https://www.xing.com/company/bwigmbh',
  },
  {
    icon: '/assets/icons/icon-youtube.svg',
    name: 'YouTube',
    url: 'https://www.youtube.com/channel/UCnwisp6s0WxTlH9ItWhHeEg',
  },
];

function FragmentSupportingSwitch({ children }) {
  const flattenedChildren = [];
  flatten(flattenedChildren, children);
  return React.createElement.apply(
    React,
    [Switch, null].concat(flattenedChildren)
  );
}
function flatten(target, children) {
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === Fragment) {
        flatten(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
}
export default function App() {
  const { loading, payload } = useQuery(fetchSitemap);
  const [forceShowConsent, setForceShowConsent] = useState(false);

  const instance = createInstance({
    urlBase: 'https://piwik.bwi.de/',
    siteId: 8,
    disabled: false,
    linkTracking: false,
  })
  
  const flatPages = [];
  if (payload && payload.pages) {
    for (const page of payload.pages) {
      flatPages.push(page);
      if (page.children) {
        for (const subpage of page.children) {
          flatPages.push(subpage);
        }
      }
    }
  }

  const { enableLinkTracking } = useMatomo()
  enableLinkTracking()

  return (
    <MatomoProvider value={instance}>

      <div className='App'>
        {!loading && payload && payload.pages && (
          <BrowserRouter>
            <CookieConsent forceShowConsent={forceShowConsent} setForceShowConsent={setForceShowConsent}/>
            <ContentControl>
              {(control) => (
                <>
                  {/* NAVIGATION */}
                  <Navigation
                    pages={payload.pages}
                    onClick={control.hideContent}
                  />

                  {/* KEYVISUAL */}
                  {!control.isPrivacyPage && (
                  <header className='header' ref={control.setHeader}>
                    <KVSlider
                      onReadClick={control.showContent}
                      slider={payload.slider}/>
                  </header>
                  )}

                  {/* MAIN CONTENT */}
                  <main ref={control.setMain}>
                    <ToOverview onClick={control.hideContent}></ToOverview>
                    <FragmentSupportingSwitch>
                      <Route key={`route-root`} exact path='/'>
                        <Redirect to={payload.pages[0].link} />
                      </Route>
                      {flatPages.map((page, i) => (
                        <Route
                          exact
                          key={`route-${i}`}
                          path={page.link}
                          render={(props) => (
                            <Page
                              {...props}
                              data={page.data}
                              pathname={page.link}
                              index={i}
                              pages={flatPages}
                              onPaginationClick={control.hideContent}
                            />
                          )}
                        />
                      ))}
                      <Route key={`datenschutz`} exact path='/datenschutzerklaerung'>
                        <Datenschutzerklaerung showContent={control.showContent} setForceShowConsent={setForceShowConsent}/>
                      </Route>
                      <Route key={`catch-all`} path='*'>
                        <Redirect to={payload.pages[0].link} />
                      </Route>
                    </FragmentSupportingSwitch>
                  </main>

                  {/* FOOTER */}
                  <Footer
                    ref={control.setFooter}
                    video={payload.slider.video}
                    menu={payload.footer}
                    socialChannels={socialChannels}
                    onToTopClick={control.hideContent}
                    setForceShowConsent={setForceShowConsent}
                  />
                </>
              )}
            </ContentControl>
          </BrowserRouter>
        )}
      </div>
      </MatomoProvider>
  );
}

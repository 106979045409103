import { Container, Row, Col, Nav } from "react-bootstrap";
import { renderHtml } from '../utils/RenderHtml';
import { TopArrow } from "./Modules/TopArrow";
import React from 'react';
import { ImageCopyright } from "./Modules/ImageCopyright";

const footerNavMenu = [
  {link: "https://www.bwi.de/impressum", title: "Impressum",},
  {link: "/datenschutzerklaerung", title: "Datenschutz", target:"_self"},
  {link: "https://www.bwi.de/news-blog", title: "BWI Blog",},
]

export const Footer = React.forwardRef(({ video, menu, socialChannels, onToTopClick, setForceShowConsent}, ref) => {
  function showCookieConsent(e) {
    setForceShowConsent(true)
    e.preventDefault()
  }
  return <footer ref={ref}>
    <TopArrow onClick={onToTopClick} />
    <div className="mood-video__wrapper">
      <video className="footer__video" muted loop autoPlay playsInline src={video.video_src}></video>
      <ImageCopyright image_copyright={video.video_copyright}/>
    </div>
    <Container className="footer__content">
      <Row className="align-items-center">
        <Col md="3" lg="2" className="footer__copyright">
          © BWI GmbH {new Date().getFullYear()}
        </Col>
        <Col md="9" lg="auto">
          <Nav id="footer-nav">
            {footerNavMenu.map((item, j) => (
              <Nav.Item key={j}>
                <Nav.Link href={item.link} target={item.target ? item.target : "_blank"}>{renderHtml(item.title)}</Nav.Link>
              </Nav.Item>
            ))}
            <Nav.Item key={'cookie-option'}>
                <Nav.Link href="#" onClick={showCookieConsent}>{renderHtml("Cookie Einstellungen")}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col md="12" lg="auto">
          {socialChannels && <ul className="footer__social-media-list">
            {socialChannels.map((channel, i) => (
              <li key={i} className="social-media__item">
                <a href={channel.url} target="_blank" title={channel.name} rel={"noreferrer"}><img src={channel.icon} alt={`BWI auf ${channel.name}`} /></a>
              </li>
            ))}
          </ul>
          }
        </Col>
      </Row>
    </Container>
  </footer>
});
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import 'svg-classlist-polyfill';
import 'objectFitPolyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './css/main.scss';
import App from './App';

import { createClient, ClientContextProvider } from 'react-fetching-library';

import { requestHostInterceptor, requestAuthInterceptor, responseInterceptor } from './utils/Typo3Adapter';

const client = createClient({
  //None of the options is required
  requestInterceptors: [requestHostInterceptor('https://api.broschuere.bwi.de')],// requestAuthInterceptor('bwi', 'preview')],
  responseInterceptors: [responseInterceptor],
});

ReactDOM.render(
  <React.StrictMode>
    <ClientContextProvider client={client}>
      <App />
    </ClientContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React from 'react'
import { Container } from 'react-bootstrap';
import { DynamicComponent } from '../../utils/DynamicComponent';
import { useInView } from 'react-intersection-observer';
import { LayoutClasses } from '../LayoutClasses';
import { ImageCopyright } from './ImageCopyright';

export function StructuredContent({ layout, settings, columns, image, intro, type }) {

  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  React.useEffect(() => {
    window.objectFitPolyfill();
  }, [])

  const bgPosClass = `bgpos--${settings.background_position.length ? settings.background_position : 'none'}`

  return <section id={settings.id} ref={ref} className={`structured-content layout--${layout} ${inView ? '-in-view' : ''}`}>
    {image && image[0] && (
      <div className={`structured-content__bg ${bgPosClass}`}>
        <img src={image[0].publicUrl} alt="" title={image[0].properties.title} data-object-fit="none" data-object-position={settings.background_position.length ? settings.background_position : 'center'} />
      </div>
    )}
    {intro && (<ImageCopyright image_copyright={image[0].properties.title}/>)}
    <Container>
      <LayoutClasses layout_classes={settings.layout_classes} type={type}>
        <div className="structured-content__wrapper">
          {intro && image && image[0] && (<style>
            {`body {
          background-image: url(${image[0].publicUrl});
          background-attachment: fixed;
          background-position: center;
          background-repeat: no-repeat;
        }`};
          </style>
          )}
          < div className="column--left">
            {columns[0] && columns[0].modules.map((descriptor, i) =>
              <React.Fragment key={i}>
                {DynamicComponent({ ...descriptor, layout: layout, key: i, section: false })}
              </React.Fragment>
            )}
          </div>
          {layout === '2col' && (<div className="column--right">
            {columns[1] && columns[1].modules.map((descriptor, j) =>
              <React.Fragment key={j}>
                {DynamicComponent({ ...descriptor, layout: layout, key: j, section: false })}
              </React.Fragment>
            )}
          </div>)}
        </div>
      </LayoutClasses>
    </Container>
  </section >
}
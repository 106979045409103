import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export function TopArrow({onClick}) {

  const location = useLocation();

  const onClickProxy = (proxyHandler, event) => {
    event.preventDefault();
    proxyHandler();
    return false;
  }

  return <Container>
    <Row>
      <Col>
        <a href={location.pathname} title="Nach oben" className="top-arrow" onClick={(event) => onClickProxy(onClick, event)}>
          <img src="/assets/icons/icon-top.svg" alt="^" />
        </a>
      </Col>
    </Row>
  </Container>
}
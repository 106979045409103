import { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { renderHtml } from '../../utils/RenderHtml';
import { useInView } from 'react-intersection-observer'
import { LayoutClasses } from '../LayoutClasses';

export function Kpi(props) {
  const { kpi_title, kpi_text, kpi_size, kpi_number, kpi_prefix, kpi_suffix } = props;

  const countingSpan = useRef();

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      gsap.to(countingSpan.current, {
        duration: 3,
        textContent: `+=${kpi_number}`,
        roundProps: "textContent",
        ease: "none"
      });
    }
  }, [kpi_number, inView]);

  return <div ref={ref} className={`kpi ${`kpi--${kpi_size} ${inView ? '-in-view' : ''}`}`}>
    <div className="kpi__inner">
      <div className="kpi__title">
        {kpi_title}
      </div>
      <div className="kpi__value">
        <span className="prefix">{renderHtml(kpi_prefix)}</span>
        <span ref={countingSpan}>0</span>
        <span className="suffix">{renderHtml(kpi_suffix)}</span>
      </div>
      <div className="kpi__text">
        {renderHtml(kpi_text)}
      </div>
    </div>
  </div>;
}

export function KPIs({ layout_classes, type, id, items }) {

  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div className={`kpis`}>
      <div className="kpis__row">
        {items && items.map((item, i) => (
          <Kpi key={`kpi-${i}`} {...item} />
        ))}
      </div>
    </div>
  </LayoutClasses>
}
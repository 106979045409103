import React from 'react';
import { Section } from '../components/Modules/Section';
import { FlexImage } from '../components/Modules/FlexImage';
import { ImageDivider } from '../components/Modules/ImageDivider';
import { KPIs } from '../components/Modules/Kpi';
import { Person } from '../components/Modules/Person';
// import { Intro } from '../components/Modules/Intro';
import { Quote } from '../components/Modules/Quote';
import { InfographicScroll } from '../components/Modules/InfographicScroll';
import { InfographicSteps } from '../components/Modules/InfographicSteps';
import { Callout } from '../components/Modules/Callout';
import { StructuredContent } from '../components/Modules/StructuredContent';
import { VideoPlayer } from '../components/Modules/VideoPlayer';
import { Interview } from '../components/Modules/Interview';
import { TwitterPost } from '../components/Modules/TwitterPost';
import { ImageBubbles } from '../components/Modules/ImageBubbles';
import { ImageBubble } from '../components/Modules/ImageBubble';
import { Expandable } from '../components/Modules/Expandable';
import { Text } from '../components/Modules/Text';
import { renderHtml } from '../utils/RenderHtml';

const Components = {
  'bwiapi_imagenet_bubble': ImageBubble, // FIXME: does not play well with IO
  'bwiapi_kpi': KPIs, // DONE
  'bwiapi_quote': Quote, // DONE
  'bwiapi_info1': InfographicScroll, // FIXME: does not play well with IO
  'bwiapi_info2': InfographicSteps, // FIXME: does not play well with IO
  'bwiapi_callout': Callout, // DONE
  'bwiapi_imagedevider': ImageDivider, // DONE
  'bwiapi_video': VideoPlayer, // DONE
  'bwiapi_interview': Interview, // DONE
  'bwiapi_twitter': TwitterPost, // DONE
  'bwiapi_imagenet': ImageBubbles, // NOT NEEDED
  'bwiapi_persona': Person, // DONE
  'bwiapi_expandable': Expandable, // DONE
  'bwiapi_image': FlexImage, // DONE
  'bwiapi_text': Text, // DONE
  'structured_content': StructuredContent, // NOT NEEDED
}

const nonSectionComponents = [
  'structured_content',
  'bwiapi_imagedevider',
  'bwiapi_imagenet_bubble',
  'bwiapi_image',
]

let i = 0;

export function DynamicComponent(descriptor, others) {
  // increment the key
  i += 1;

  // prepare the basic props
  const props = {
    key: `${descriptor.type}-${i}`,
    type: descriptor.type,
    ...others,
  }

  // if a component is not available, render a error
  if (!(descriptor.type in Components)) {
    return React.createElement(() => <div className="error">Component {descriptor.type} not available.</div>, props)
  }

  //children of descriptor props need to be assigend to antoher prop name otherwise react will null them
  if (descriptor.props && "children" in descriptor.props) {
    descriptor.props.items = descriptor.props.children;
  }

  if (descriptor.type === 'bwiapi_imagenet') {
    descriptor.props.items = descriptor.props.items.map((item) => {
      return {
        type: "bwiapi_imagenet_bubble",
        props: { ...item }
      }
    })
  }
  // render the component, optionally with children (parsed html)
  const children = "children" in descriptor ? renderHtml(descriptor.children) : null;
  if (nonSectionComponents.indexOf(descriptor.type) !== -1 || descriptor.section === false) {
    return React.createElement(Components[descriptor.type], { ...props, ...descriptor.props, modules: descriptor.modules }, children)
  }
  return <Section {...props} {...descriptor.props}> {React.createElement(Components[descriptor.type], { ...props, ...descriptor.props, modules: descriptor.modules }, children)}</Section >
}
import { renderHtml } from '../../utils/RenderHtml';
import { useInView } from 'react-intersection-observer'
import { LayoutClasses } from '../LayoutClasses';

export function TwitterPost({ content, author, profile_name, profile_url, layout_classes, type }) {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div ref={ref} className={`twitter-post ${inView ? '-in-view' : ''}`}>
      <div className="twitter-post__inner">
        <div className="twitter-post__content">
          {renderHtml(content)}
        </div>
        <div className="twitter-post__meta">
          <div className="twitter-post__author">{author}</div>
          <a className="twitter-post__profile" href={profile_url} target="_blank" rel="noreferrer">{profile_name}</a>
        </div>
      </div>
    </div>
  </LayoutClasses>
}
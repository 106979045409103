import React from 'react';
import { DynamicComponent } from "../../utils/DynamicComponent";
import { LayoutClasses } from '../LayoutClasses';

export function ImageBubbles({ id, layout_classes, items, type }) {
  return (
    <LayoutClasses layout_classes={layout_classes} type={type}>
      <div className={`image-bubbles`}>
        <div className="image-bubbles__wrapper" data-items={items.length}>
          {items.map((descriptor, idx) => { return DynamicComponent(descriptor, idx); })}
        </div>
      </div>
    </LayoutClasses>
  )
}
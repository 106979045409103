import { useCallback, useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { gsap } from 'gsap'
import { renderHtml } from '../../utils/RenderHtml';
import { createPopper } from '@popperjs/core';
import { useInView } from 'react-intersection-observer';
import { useSwipeable } from 'react-swipeable';

const ANIMATION_DURATION = 1

export function InfographicSteps({ svg_image, children_clickable, items }) {
  const svgRef = useRef();
  const plusButton = useRef();
  const popupsRef = useRef();
  const [currentStep, setNextStep] = useState(0);
  const [stepBefore, setOldStep] = useState(0);
  const [groups, setGroups] = useState();
  const [steps, setSteps] = useState();
  const [isAnimating, setIsAnimating] = useState(false);
  const [plusPopper, setPlusPopper] = useState(null)

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const swiperConfig = {
    delta: 100,                            // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
    trackTouch: true,                     // track touch input
    trackMouse: true,                    // track mouse input
    rotationAngle: 0,                     // set a rotation angle
  }
  const swipeHanlders = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (isAnimating)
        return
      let nextStep = currentStep + 1;
      if (nextStep >= steps.length)
        nextStep = 0
      setOldStep(currentStep);
      setNextStep(nextStep);
      forEachPopup(popup => {
        popup.setAttribute('data-show', false)
      })
    },
    onSwipedRight: (eventData) => {
      if (isAnimating)
        return

      let nextStep = currentStep - 1;
      if (nextStep < 0)
        nextStep = steps.length - 1;

      setOldStep(currentStep);
      setNextStep(nextStep);
      forEachPopup(popup => {
        popup.setAttribute('data-show', false)
      })
    },
    ...swiperConfig,
  });

  const animate = (pGroups, pSteps, duration = ANIMATION_DURATION, done = () => { }) => {
    const tl = gsap.timeline({
      onStart: () => {
        setIsAnimating(true)
      },
      onComplete: () => {
        setIsAnimating(false)
        done();
      }
    });
    pGroups.map((group, idx) => {
      tl.to(plusButton.current, {
        opacity: 0,
        duration: 0.2,
        ease: 'power3.inOut'
      }, 0)
        .to(group, {
          x: `+=${pSteps[currentStep][idx].x - pSteps[stepBefore][idx].x}`,
          y: `+=${pSteps[currentStep][idx].y - pSteps[stepBefore][idx].y}`,
          opacity: pSteps[currentStep][idx].opacity,
          scale: `${gsap.getProperty(group, 'scale') * pSteps[currentStep][idx].scale / pSteps[stepBefore][idx].scale}`,
          transformOrigin: 'center',
          duration: duration,
          ease: "power3.inOut",
          onComplete: () => {
            initPopper(currentStep);
          }
        }, 0.0)
        .to(plusButton.current, {
          opacity: 1,
          duration: 0.2,
          ease: 'power3.inOut'
        }, ANIMATION_DURATION)
        ;
    })
  }

  useEffect(() => {
    if (!groups || !steps || !steps[currentStep] || !steps[stepBefore] || isAnimating) {
      return
    }

    animate(groups, steps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  const addEventListenersToHidePopupsOnScroll = () => {
    window.addEventListener('scroll', () => {
      forEachPopup(popup => {
        popup.setAttribute('data-show', false)
      })
    })
  }

  useEffect(addEventListenersToHidePopupsOnScroll, [])

  const initPopper = (step) => {

    if (!svgRef.current) {
      return;
    }

    const referenceSel = `#g${step + 1}`

    if (plusPopper) {
      plusPopper.destroy();
    }

    const reference = svgRef.current.svgWrapper.querySelector(referenceSel)

    if (reference && plusButton.current) {
      const popperInstance = createPopper(reference, plusButton.current, {
        placement: "bottom",
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          },
          {
            name: 'flip',
            enabled: false,
          },
        ]
      })
      setPlusPopper(popperInstance)
    }
  }

  const setupSvgAfterInjection = useCallback((_err, svg) => {
    if (_err) {
      console.error('Could not load SVG')
    }
    let groups = Array.from(svg.querySelectorAll('#original > [id^=g]')).reverse()
    //remember layers are inverted!
    setGroups(groups);
    let steps = groups.map((el, idx) => {
      return Array.from(svg.querySelectorAll(`#s${idx + 1
        } > [id ^= s${idx + 1}]`)).reverse().map(el => {
          const x = el.getAttribute("cx")
          const y = el.getAttribute("cy")
          const opacity = el.getAttribute('opacity') || 1;
          const scale = el.getAttribute('r') || 1;
          return { x, y, opacity, scale }
        })
    });
    setSteps(steps);

    animate(groups, steps, 1)

    initPopper(currentStep)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgRef.current]);

  const setupSvgBeforeInjection = useCallback((svg) => (
    svg.querySelectorAll('[id^=s]').forEach(el => { el.classList.add('d-none') })
  ), [])

  const forEachPopup = (callback) => {
    if (popupsRef.current) {
      for (const popup of popupsRef.current.querySelectorAll('.popup')) {
        callback(popup)
      }
    }
  }

  const showPopupForCurrentStep = (event) => {

    event.preventDefault();

    const popupSel = `#btn_popup_${currentStep + 1}_popup`
    const popup = popupsRef.current.querySelector(popupSel);

    if (popup) {
      if (!popup.dataset.initialized) {
        popup.dataset.initialized = true

        // center it
        const offsetY = -1 * popup.offsetHeight / 2

        createPopper(event.currentTarget, popup, {
          placement: "top",
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, offsetY],
              },
            },
            {
              name: 'flip',
              enabled: false,
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                padding: 16
              },
            },
          ],
        })
      }
      forEachPopup(popup => popup.setAttribute('data-show', false))
      setTimeout(() => popup.setAttribute('data-show', true), 1)
    } else {
      console.warn("Infographic: Button event handler has not popup info.")
    }
  }

  const addEventListenersToClosePopupsOnClose = () => {
    forEachPopup(popup => {
      popup.querySelector('.popup__close').addEventListener('click', () => {
        popup.setAttribute('data-show', false)
      })
    });
  }

  useEffect(() => {
    addEventListenersToClosePopupsOnClose();
  })

  const handleOutsideClick = (event) => {
    const els = document.elementsFromPoint(event.clientX, event.clientY);
    let containsPopup = false;
    for (const el of els) {
      if (el.classList.contains('popup')) {
        containsPopup = true
        break;
      }
    }
    if (!containsPopup) {
      forEachPopup(popup => popup.setAttribute('data-show', false))
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  })

  return <div className={`infographic-steps`} {...swipeHanlders}>
    {/* SVG GRAPHICS */}
    <ReactSVG
      ref={svgRef}
      src={svg_image}
      className="infographic-steps__wrapper"
      afterInjection={setupSvgAfterInjection}
      beforeInjection={setupSvgBeforeInjection}
    />

    {/* PLUS BUTTON */}
    {(Number(children_clickable) === 1) && (
      <div ref={plusButton} className="infographic-steps__plus__wrapper">
        <button className="infographic-steps__plus" href="#" onClick={showPopupForCurrentStep}></button>
      </div>
    )}

    {/* STEP DESCRIPTION */}
    {(Number(children_clickable) === 0) && items && items[currentStep] && (<div className="infographic-steps__step_content">
      <div className="infographic-steps__step__headline">{items[currentStep].step_title}</div>
      <div className="infographic-steps__step__text">{items[currentStep].step_text && renderHtml(items[currentStep].step_text)}</div>
    </div>)}

    <div className="popups" ref={popupsRef}>
      {items.map((item, i) => {
        return <div id={`btn_popup_${i + 1}_popup`} key={`step-popup-${i}`} className="popup" data-show="false">
          <div className="popup__close"></div>
          <h3>{item.step_title}</h3>
          {item.step_text && item.step_text.length && renderHtml(item.step_text)}
        </div>
      })}
    </div>

    {/* STEPS */}
    <div className="infographic-steps__navigation">
      <div className="buttons__wrapper">
        {items && items.map((step, i) => (
          <button key={`step-button-${i}`} className={`step__button${(currentStep === i) ? ' active' : ''}`} {...(isAnimating) && { disabled: true }} onClick={(e) => {
            if (isAnimating)
              return
            setOldStep(currentStep)
            setNextStep(i)
          }}>
            {i + 1}
          </button>
        ))
        }
      </div>
    </div>
  </div >
}
import React, { useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { DynamicComponent } from '../utils/DynamicComponent';
import { Pagination } from './Pagination';

const fetchPage = (pathname) => ({
  method: 'GET',
  endpoint: `${pathname}`,
  config: {
    type: 'page',
  },
});
export function MainContent({ modules }) {
  return (
    <section id='main'>
      {modules.map((descriptor, i) => {
        return (
          <React.Fragment key={i}>
            {DynamicComponent(descriptor)}
          </React.Fragment>
        );
      })}
    </section>
  );
}
export function Intro({ modules }) {
  return (
    <section id='intro'>
      {modules.map((descriptor, i) => {
        return (
          <React.Fragment key={i}>
            {DynamicComponent(descriptor, { intro: true })}
          </React.Fragment>
        );
      })}
    </section>
  );
}

export function Page(props) {
  const { loading, payload, query } = useParameterizedQuery(fetchPage);
  useEffect(() => {
    if (!payload) {
      query(props.pathname);
    }
  }, [payload, query, props.pathname]);
  return (
    <>
      {!loading && payload && (
        <>
          <Intro modules={payload.intro} />
          <MainContent modules={payload.main} />
          <Pagination
            index={props.index}
            pages={props.pages}
            onPaginationClick={props.onPaginationClick}
          ></Pagination>
        </>
      )}
    </>
  );
}

import { Accordion } from "react-bootstrap";
import { AccordionToggle } from './AccordionToggle';
import { renderHtml } from '../../utils/RenderHtml';
import { useInView } from 'react-intersection-observer'
import { LayoutClasses } from '../LayoutClasses';

export function Expandable({ title, content, trigger_position, layout_classes, type }) {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div ref={ref} className={`expandable ${inView ? '-in-view' : ''}`}>
      <Accordion defaultActiveKey="-1">
        <div className="accordion__item">
          <AccordionToggle togglePosition={trigger_position} eventKey="0">
            {title}
          </AccordionToggle>
          <Accordion.Collapse timeout={1000} eventKey="0" className="accordion__body">
            <div className="accordion__body__inner">{content && (renderHtml(content))}</div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </div>
  </LayoutClasses>
}
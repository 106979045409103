import { Container, Row, Col } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer'

export function Section({ id, children, type }) {

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return <section ref={ref} id={id} className={`${type} ${inView ? '-in-view' : ''}`}>
    <Container>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
  </section>
}
import { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';

export function Navigation({ pages, onClick }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const navbarCollapse = useRef();

  const customOnToggle = () => {
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  const closeNav = () => {
    setIsExpanded(false);
    onClick();
    document.body.style.overflow = 'auto';
  };

  const hideIfClickedOutside = (event) => {
    if (!isExpanded) return;
    const els = document.elementsFromPoint(event.clientX, event.clientY);
    let containsNavbar = false;
    for (const el of els) {
      if (el.classList.contains('navbar')) {
        containsNavbar = true;
        break;
      }
    }
    if (!containsNavbar) {
      setIsExpanded(!isExpanded);
      if (isExpanded) {
        document.body.style.overflow = 'auto';
      } else {
        document.body.style.overflow = 'hidden';
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', hideIfClickedOutside);
    return () => {
      window.removeEventListener('click', hideIfClickedOutside);
    };
  });

  return (
    <Navbar
      onToggle={customOnToggle}
      expanded={isExpanded}
      collapseOnSelect={true}
      bg='black'
      variant='dark'
      expand=''
      fixed='top'
    >
      <Container>
        <Navbar.Toggle aria-controls='header-navbar'>
          <span className='icon-bar'></span>
          <span className='icon-bar'></span>
          <span className='icon-bar'></span>
        </Navbar.Toggle>
        <div className='header-navbar__label'>Inhalt</div>
        <Navbar.Brand href='https://bwi.de' target="_blank">
          <img
            src='/assets/images/bwi-logo.svg'
            className='d-inline-block align-top'
            alt='BWI Broschüre'
            height='46px'
            width='100px'
          />
        </Navbar.Brand>
        <Navbar.Collapse timeout={1000} id='header-navbar' ref={navbarCollapse}>
          {pages && (
            <Nav id='main-nav'>
              {pages.map((page, i) => (
                <Nav.Item key={`page-${i}`} className='main-item'>
                  <NavLink
                    exact
                    key={`page-link-${i}`}
                    to={page.link}
                    onClick={closeNav}
                  >
                    {parse(page.title)}
                  </NavLink>
                  {/* <Link key={`page-link-${i}`} to={page.link} onClick={closeNav}>{parse(page.title)}</Link> */}
                  {page.children &&
                    page.children.map((subpage, j) => (
                      <Nav.Item key={`page-${i}-${j}`} className='sub-item'>
                        <NavLink
                          exact
                          key={`page-link-${i}-${j}`}
                          to={subpage.link}
                          onClick={closeNav}
                        >
                          {parse(subpage.title)}
                        </NavLink>
                      </Nav.Item>
                    ))}
                </Nav.Item>
              ))}
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

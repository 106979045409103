import { useCallback, useEffect, useRef } from 'react'
import { ReactSVG } from 'react-svg'
import SimpleBar from 'simplebar-react';
import { createPopper } from '@popperjs/core';
import { dragscroll } from '../../utils/DragScroll'
import { gsap } from "gsap";
import { renderHtml } from '../../utils/RenderHtml';
import { useInView } from 'react-intersection-observer'

function pointInElement(x, y, domNode) {
  const rect = domNode.getBoundingClientRect();
  return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
}

export function InfographicScroll({ bg_image, svg_image, viewport_width, items }) {

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });
  const svgRef = useRef();
  const popupsRef = useRef();
  const scrollbarRef = useRef();
  const leftMask = useRef();
  const rightMask = useRef();
  const bgImgRef = useRef();

  const forEachButton = (callback) => {
    if (svgRef.current && svgRef.current.svgWrapper) {
      const rootNode = svgRef.current.svgWrapper.querySelector('#buttons')
      if (!rootNode) return false;
      const buttons = rootNode.querySelectorAll('g')
      for (const button of buttons) {
        callback(button)
      }
    }
  }

  const forEachPopup = (callback) => {
    if (popupsRef.current) {
      for (const popup of popupsRef.current.querySelectorAll('.popup')) {
        callback(popup)
      }
    }
  }

  const clickIsOnMask = (event) => {
    const clickInsideLeftMask = pointInElement(event.clientX, event.clientY, leftMask.current)
    const clickInsideRightMask = pointInElement(event.clientX, event.clientY, rightMask.current)
    return clickInsideLeftMask || clickInsideRightMask;
  }

  const plusActionHandler = (event) => {

    const button = event.currentTarget

    const clickInsideLeftMask = pointInElement(event.clientX, event.clientY, leftMask.current)
    const clickInsideRightMask = pointInElement(event.clientX, event.clientY, rightMask.current)
    if (clickInsideLeftMask || clickInsideRightMask) { return }

    const popupSel = `#${button.id}_popup`
    const popup = popupsRef.current.querySelector(popupSel);

    if (popup) {
      if (!popup.dataset.initialized) {
        popup.dataset.initialized = true

        // center it
        const offsetY = -1 * popup.offsetHeight / 2

        createPopper(event.currentTarget, popup, {
          placement: "top",
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, offsetY],
              },
            },
            {
              name: 'flip',
              enabled: false,
            },
            {
              name: 'preventOverflow',
              options: {
                altAxis: true,
                padding: 16
              },
            },
          ],
        })
      }
      forEachPopup(popup => popup.setAttribute('data-show', false))
      setTimeout(() => popup.setAttribute('data-show', true), 1)
    } else {
      console.warn("Infographic: Button event handler has not popup info.")
    }
  }

  const registerClickHandlers = (svgRef) => {
    forEachButton(button => button.addEventListener('click', plusActionHandler))
  }

  const setupSvgAfterInject = useCallback((_err, svg) => {
    if (_err) {
      return console.error('Could not load SVG')
    }

    registerClickHandlers(svgRef)
    forEachButton(button => {
      button.addEventListener('mousemove', (event) => {
        if (!clickIsOnMask(event)) {
          const hoverData = event.currentTarget.getAttribute('data-hover')
          if (hoverData !== "true") {
            event.currentTarget.setAttribute('data-hover', true)
            gsap.to(event.currentTarget, { scale: 1.1, transformOrigin: 'center', ease: 'power3.inOut' })
          }
        } else {
          event.currentTarget.setAttribute('data-hover', false)
          gsap.to(event.currentTarget, { scale: 1, transformOrigin: 'center', ease: 'power3.inOut' })
        }
      })
      button.addEventListener('mouseleave', (event) => {
        event.currentTarget.setAttribute('data-hover', false)
        gsap.to(event.currentTarget, { scale: 1, transformOrigin: 'center', ease: 'power3.inOut' })
      })
    })

    // this works in IE I think
    const containerHeight = svg.parentNode.parentNode.getBoundingClientRect().height

    const viewBox = {
      width: svg.viewBox.baseVal.width,
      height: svg.viewBox.baseVal.height
    }

    const ratio = containerHeight / viewBox.height

    const svgSize = {
      width: viewBox.width * ratio,
      height: containerHeight
    }

    svg.style.width = `${svgSize.width}px`;
    svg.style.height = `${svgSize.height}px`;

    bgImgRef.current.style.width = `${svgSize.width}px`;
    bgImgRef.current.style.height = `${svgSize.height}px`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgRef.current,]);



  const addEventListenersToHidePopupsOnScroll = () => {
    scrollbarRef.current.getScrollElement().addEventListener('scroll', (event) => {
      forEachPopup(popup => {
        popup.setAttribute('data-show', false)
      })
    })
    window.addEventListener('scroll', () => {
      forEachPopup(popup => {
        popup.setAttribute('data-show', false)
      })
    })
  }

  const addEventListenersToClosePopupsOnClose = () => {
    forEachPopup(popup => {
      popup.querySelector('.popup__close').addEventListener('click', () => {
        popup.setAttribute('data-show', false)
      })
    });
  }

  useEffect(() => {
    addEventListenersToHidePopupsOnScroll()
    addEventListenersToClosePopupsOnClose();

    dragscroll.init('.simplebar-content-wrapper');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollbarRef.current])

  const handleOutsideClick = (event) => {
    const els = document.elementsFromPoint(event.clientX, event.clientY);
    let containsPopup = false;
    for (const el of els) {
      if (el.classList.contains('popup')) {
        containsPopup = true
        break;
      }
    }
    if (!containsPopup) {
      forEachPopup(popup => popup.setAttribute('data-show', false))
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  })

  const viewportWidthMatch = viewport_width.match(/\d+/)
  if (viewportWidthMatch) {
    viewport_width = viewportWidthMatch[0]
  } else {
    viewport_width = 80
  }

  const maskWidth = Math.floor((100 - viewport_width) / 2)

  return <div className={`infographic__scroll`}>

    <div className="infographic__scroll__left-mask" ref={leftMask} style={{ width: `${maskWidth}%` }}></div>

    <SimpleBar ref={scrollbarRef} forceVisible='x' autoHide={false} style={{ width: "100%" }} scrollbarMinSize={100} scrollbarMaxSize={100}>
      <div className="infographic__scroll__wrapper">
        <img className="infographic__scroll__bg" ref={bgImgRef} src={`${bg_image}`} alt="" />
        <ReactSVG
          className="infographic__scroll__svg__wrapper"
          ref={svgRef}
          src={`${svg_image}`}
          afterInjection={setupSvgAfterInject} />
      </div>
    </SimpleBar>
    <div className="infographic__scroll__mobile-indicator">
      ← Ziehen →
    </div>
    <div className="infographic__scroll__right-mask" ref={rightMask} style={{ width: `${maskWidth}%` }}></div>

    <div className="popups" ref={popupsRef}>
      {items.map((item, i) => {
        return <div key={`popup-${i}`} id={`btn_popup_${i + 1}_popup`} className="popup" data-show="false">
          <div className="popup__close"></div>
          <h3>{item.step_title}</h3>
          {renderHtml(item.step_text)}
        </div>
      })}
    </div>

  </div>
}
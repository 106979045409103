import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Pagination = ({ index, pages, onPaginationClick }) => {
  let prevPage = null;
  let nextPage = null;
  let currentPage = pages[index];

  if (index >= 1) {
    prevPage = pages[index - 1];
  }

  if (index < pages.length - 1) {
    nextPage = pages[index + 1];
  }

  return (
    <section className='pagination'>
      <Container>
        <Row>
          <Col className='pagination__wrapper'>
            <Row>
              <Col className='pagination__col pagination__col--left'>
                {prevPage && (
                  <Link
                    className='pagination__link pagination__link--prev'
                    exact='true'
                    to={prevPage.link}
                    onClick={onPaginationClick}
                  >
                    <span>
                      Vorheriges
                      <br />
                      Kapitel
                    </span>
                  </Link>
                )}
                {!prevPage && (
                  <a className='pagination__link pagination__link--prev -inactive'>
                    <span>
                      Vorheriges
                      <br />
                      Kapitel
                    </span>
                  </a>
                )}
              </Col>
              <Col className="pagination__col pagination__col--center">
                <div className='pagination__bullets'>
                  {pages.map((page, idx) => {
                    return <Link
                      className={`pagination__bullet__link`}
                      key={`pagination-bullet-${idx}`}
                      exact='true'
                      to={page.link}
                      onClick={onPaginationClick}
                    ><span className={`pagination__bullet${(page === currentPage) ? ' active' : ''}`}></span>
                    </Link>;
                  })}
                </div>
              </Col>
              <Col className='pagination__col pagination__col--right'>
                {nextPage && (
                  <Link
                    className='pagination__link pagination__link--next'
                    exact='true'
                    to={nextPage.link}
                    onClick={onPaginationClick}
                  >
                    <span>
                      Nächstes
                      <br />
                      Kapitel
                    </span>
                  </Link>
                )}
                {!nextPage && (
                  <a className='pagination__link pagination__link--next -inactive'>
                    <span>
                      Nächstes
                      <br />
                      Kapitel
                    </span>
                  </a>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

import { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useLocation } from 'react-router-dom';

const ANIM_DURATION = 1.0;
const ANIM_BUFFER_TIME = 500; // add 500ms to prevent direct up/down scrolling
const ANIM_EASING = 'power3.inOut';
const TOUCH_SPEED_SCROLL_THRESHOLD = 10;
const WHEEL_SCROLL_SPEED_THRESHOLD = 50;

export function ContentControl({ children }) {
  const ref = useRef();

  const [isContentShown, setIsContentShown] = useState(false);
  const [header, setHeader] = useState(null);
  const [main, setMain] = useState(null);
  const [footer, setFooter] = useState(null);

  const location = useLocation();

  const animations = {
    hideHeader: () => {
      if (header) {
        gsap.fromTo(
          header,
          {
            maxHeight: `100vh`,
          },
          {
            maxHeight: 0,
            ease: ANIM_EASING,
            duration: ANIM_DURATION,
          }
        );
      }
    },
    showHeader: () => {
      if (header) {
        gsap.to(header, {
          maxHeight: '100vh',
          ease: ANIM_EASING,
          duration: ANIM_DURATION,
        });
      }
    },
    hideMain: () => {
      if (main) {
        main.style.maxHeight = `${main.offsetHeight}px`;
        gsap.to(main, {
          maxHeight: '0px',
          ease: ANIM_EASING,
          duration: ANIM_DURATION,
        });
      }
    },
    showMain: () => {
      if (main)
        main.style.maxHeight = 'none';
    },
    showFooter: () => {
      if (footer)
        footer.style.maxHeight = 'none';
    },
    hideFooter: () => {
      if (footer) {
        footer.style.maxHeight = `${footer.offsetHeight}px`;
        gsap.to(footer, {
          maxHeight: '0px',
          duration: ANIM_DURATION,
          ease: ANIM_EASING,
        });
      }
    },
  };

  let isAnimating = false;
  useEffect(() => {
    isAnimating = true;
    setTimeout(() => {
      isAnimating = false;
    }, ANIM_DURATION * 1000 + ANIM_BUFFER_TIME);
  }, [isContentShown]);

  const showContent = () => {
    if (header && main && footer) {
      setIsContentShown(true);
      animations.hideHeader();
      animations.showMain();
      animations.showFooter();
    }
  };

  const hideContent = () => {
    if (header && main && footer) {
      setIsContentShown(false);
      animations.showHeader();
      animations.hideFooter();
      animations.hideMain();
    }
  };

  let speed = 0;
  let timeout;
  const onWheel = (e) => {
    if (header && main && footer) {
      if (isAnimating) return;

      if (timeout) clearTimeout(timeout);

      speed += e.deltaY;
      speed /= 2;
      timeout = setTimeout(() => (speed = 0), 600);
      if (!isContentShown && speed >= WHEEL_SCROLL_SPEED_THRESHOLD) {
        setTimeout(() => window.scrollTo(0, 0), 100); // needs delay of 1, dunno y
        showContent();
      } else if (
        isContentShown &&
        window.pageYOffset <= 16 &&
        speed <= -WHEEL_SCROLL_SPEED_THRESHOLD
      ) {
        setTimeout(() => window.scrollTo(0, 0), 100); // needs delay of 1, dunno y
        hideContent();
      }
    }
    return false;
  };

  const onWheelBound = onWheel.bind(this);

  let touchSpeed = 0;
  let setTouchSpeed = (speed) => (touchSpeed = speed);

  let touchStartY = 0;
  let setTouchStartY = (y) => (touchStartY = y);

  const onTouchStart = (event) => {
    setTouchSpeed(0);
    setTouchStartY(event.touches[0].clientY);
  };

  const onTouchMove = (event) => {
    const deltaY = touchStartY - event.touches[0].clientY;
    setTouchSpeed((touchSpeed + deltaY * 2) / 3);
    setTouchStartY(event.touches[0].clientY);
  };

  const onTouchEnd = (event) => {
    if (header && main && footer) {
      if (!isContentShown && touchSpeed >= TOUCH_SPEED_SCROLL_THRESHOLD) {
        setTimeout(() => window.scrollTo(0, 0), 100); // needs delay of 1, dunno y
        showContent();
      } else if (
        isContentShown &&
        window.pageYOffset <= 16 &&
        touchSpeed <= -TOUCH_SPEED_SCROLL_THRESHOLD
      ) {
        setTimeout(() => window.scrollTo(0, 0), 100); // needs delay of 1, dunno y
        hideContent();
      }
    }
  };

  const onKeyDown = (event) => {
    if (event.which === 38 && window.pageYOffset <= 16 && isContentShown) {
      // 38 uparrow
      hideContent();
    } else if (
      (event.which === 40 || event.which === 32 || event.which === 13) &&
      !isContentShown
    ) {
      // 40 downarrow 32 space 13 enter
      event.preventDefault();
      showContent();
    }
  };

  useEffect(() => {
    document.body.addEventListener('wheel', onWheelBound);
    document.body.addEventListener('touchstart', onTouchStart);
    document.body.addEventListener('touchend', onTouchEnd);
    document.body.addEventListener('touchmove', onTouchMove);
    document.body.addEventListener('keydown', onKeyDown);
    return () => {
      document.body.removeEventListener('wheel', onWheelBound);
      document.body.removeEventListener('touchstart', onTouchStart);
      document.body.removeEventListener('touchend', onTouchEnd);
      document.body.removeEventListener('touchmove', onTouchMove);
      document.body.removeEventListener('keydown', onKeyDown);
    };
  });

  // fixes iOS 100vh issue
  useEffect(() => {
    let interval = setInterval(() => {
    let header = document.querySelector('.header')
    if (header)
      header.style.height = `${window.innerHeight}px`;
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [isPrivacyPage, setIsPrivacyPage] = useState(false);
  
  useEffect(() => {
    if (location) {
      setIsPrivacyPage(location.pathname === '/datenschutzerklaerung');
    }
  }, [location]);

  const childrenArgs = {
    showContent,
    hideContent,
    setHeader,
    setMain,
    setFooter,
    isPrivacyPage
  };

  return (
    <div
      ref={ref}
      className={`content-control ${isContentShown ? '-show-content' : ''
        } route${location.pathname.replace('/', '__')}`}
    >
      {children(childrenArgs)}
    </div>
  );
}

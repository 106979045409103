import {useCookieConsent} from '@use-cookie-consent/core';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useMatomo } from '@datapunt/matomo-tracker-react'

import Toggle from 'react-toggle'

export const CookieConsent = ({forceShowConsent, setForceShowConsent}) => {
  const {consent, acceptAllCookies, acceptCookies} = useCookieConsent({ consentCookieAttributes:{ expires: 365, path: "" }});
  const [statisticsAccepted, setStatisticsAccepted] = useState(false);
  
  const location = useLocation();
  const { trackPageView } = useMatomo()
  const [isPrivacyPage, setIsPrivacyPage] = useState(false);
  const [shouldShowConsent, setShouldShowConsent] = useState(true);

   // Track page view
   useEffect(() => {
    if (consent.statistics) {
      trackPageView()
    }
  }, [location.pathname]);
  
  useEffect(() => {
    setStatisticsAccepted(consent.statistics);
    if (Object.keys(consent).length > 0) {
      setShouldShowConsent(false)
      setForceShowConsent(false)
    }
  }, [consent]);

  useEffect(() => {
    if (location) {
      setIsPrivacyPage(location.pathname === '/datenschutzerklaerung')
    }
  }, [location]);

  return (<>
    {((!isPrivacyPage && shouldShowConsent) || forceShowConsent)  && (
    <div className="cookie-consent">
      <div className="cookie-consent__content">
      <h3>Cookie Einstellungen</h3>
      <p>Um die Leistungen der Website zu optimieren verwenden wir Cookies. Die Cookie Einstellungen lassen sich über den Menüpunkt <a href="/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> jederzeit aufrufen.</p>
      <label>
        <Toggle 
        disabled={true}
        defaultChecked={true}
        icons={false}
        />
        <span className="react-toggle__name">Erforderliche Cookies</span>
      </label>
      <label>
        <Toggle
        defaultChecked={consent.statistics}
        icons={false}
        onChange={() => {setStatisticsAccepted(prevStatisticsAccepted => !prevStatisticsAccepted)}} 
        />
        <span className="react-toggle__name">Analyse-Cookies</span>
      </label>
      <div className="cookie-consent__button__wrapper">
        <a href="/datenschutzerklaerung#cookies" target="_blank">Cookie Details</a>
        <button className="btn__read btn btn-dark" onClick={acceptAllCookies}>Alle Akzeptieren</button>
        <button className="btn__read btn btn-dark" onClick={() => {
          acceptCookies({necessary: true, statistics: statisticsAccepted});
          }}>Auswahl Akzeptieren</button>
      </div>
      <div className="cookie-consent__links">
        <a href="/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a>
        <a href="https://www.bwi.de/impressum" target="_blank">Impressum</a>
      </div>
      </div>
    </div>
    )}</>
  );
};

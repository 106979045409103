import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { useContext } from 'react';
import { AccordionContext } from 'react-bootstrap';

export function AccordionToggle(props) {

  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(props.eventKey, () => { });

  const isCurrentEventKey = currentEventKey === props.eventKey;

  return <div className={`accordion__toggle toggle--${props.togglePosition} accordion__toggle--${isCurrentEventKey ? 'active' : 'inactive'}`} onClick={decoratedOnClick}>
    <div className="accordion__title">
      {props.children}
    </div>
    <div className="accordion__toggle__icon"></div>
  </div>
}
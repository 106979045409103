import { Container } from 'react-bootstrap';

export function ToOverview({ onClick }) {
  return (
    <div className='to-overview__wrapper'>
      <Container>
        <div className='to-overview' onClick={onClick}>
          <img
            className='to-overview__icon'
            src='/assets/icons/icon-top.svg'
            alt='^'
          />
          <span className='to-overview__label'>Zum Kapitelanfang</span>
        </div>
      </Container>
    </div>
  );
}

/**
 * @fileoverview dragscroll - scroll area by dragging
 * @version 0.0.8
 * 
 * @license MIT, see http://github.com/asvd/dragscroll
 * @copyright 2015 asvd <heliosframework@gmail.com> 
 */

var _window = window;
var _document = document;
var mousemove = 'mousemove';
var mouseup = 'mouseup';
var mousedown = 'mousedown';
var EventListener = 'EventListener';
var addEventListener = 'add'+EventListener;
var newScrollX, newScrollY;

var dragged = [];
var init = function(selector) {
  let i;

    // cloning into array since HTMLCollection is updated dynamically
    dragged = [].slice.call(_document.querySelectorAll(selector));
    for (i = 0; i < dragged.length;) {
      // eslint-disable-next-line no-loop-func
      (function(el, lastClientX, lastClientY, pushed, scroller, cont){
            if (el.dataset.dragscrollInitialized) {
              return;
            }
            
            el.dataset.dragscrollInitialized = true;
            
            (cont = el.container || el)[addEventListener](
                mousedown,
                cont.md = function(e) {
                    if (!el.hasAttribute('nochilddrag') ||
                        _document.elementFromPoint(
                            e.pageX, e.pageY
                        ) === cont
                    ) {
                        pushed = 1;
                        lastClientX = e.clientX;
                        lastClientY = e.clientY;

                        e.preventDefault();
                    }
                }, 0
            );

            _window[addEventListener](
                mouseup, cont.mu = function() {pushed = 0;}, 0
            );

            _window[addEventListener](
                mousemove,
                cont.mm = function(e) {
                    if (pushed) {
                        (scroller = el.scroller||el).scrollLeft -=
                            newScrollX = (- lastClientX + (lastClientX=e.clientX));
                        scroller.scrollTop -=
                            newScrollY = (- lastClientY + (lastClientY=e.clientY));
                        if (el === _document.body) {
                            (scroller = _document.documentElement).scrollLeft -= newScrollX;
                            scroller.scrollTop -= newScrollY;
                        }
                    }
                }, 0
            );
          })(dragged[i++]);
    }
}

export const dragscroll = { init }
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'
import { ImageCopyright } from './ImageCopyright';

export function ImageDivider({ image, image_title }) {

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  useEffect(() => {
    window.objectFitPolyfill();
  }, [])

  return <div ref={ref} className={`image-divider ${inView ? '-in-view' : ''}`}>
    <img src={image} title={image_title} alt='' data-object-fit="cover" data-object-position="center" />
    <ImageCopyright image_copyright={image_title}/>
  </div>
}
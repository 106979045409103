import base64 from 'base-64';

export function requestHostInterceptor(host) {
  return () => async action => {
    return {
      ...action,
      endpoint: `${host}${action.endpoint}`,
    };
  }
};

export function requestAuthInterceptor(login, password) {
  return () => async action => {
    return {
      ...action,
      headers: {
        ...action.headers,
        Authorization: `Basic ${base64.encode(`${login}:${password}`)}`
      },
    };
  }
};

export function responseInterceptor(client) {
  return async (action, response) => {
    const payload = response.payload;
    let adaptedPayload;
    switch (action.config.type) {
      case 'sitemap':
        adaptedPayload = extractSitemapFromPayload(payload);
        break;
      case 'page':
        adaptedPayload = extractPageContentFromPayload(payload);
        break;
      default:
        adaptedPayload = payload;
        break;
    }
    return {
      ...response,
      payload: adaptedPayload
    };
  }
};

const extractSitemapFromPayload = (payload) => {
  return {
    pages: payload.content.colPos0[0].content.menu,
    footer: payload.content.colPos0[1].content.menu,
    slider: getHeaderSliderData(payload.content.colPos0[2])
  }
}

const extractPageContentFromPayload = (payload) => {
  //colPos0 is main content
  //colPos1 is intro
  let intro = []
  if (payload.content.colPos1) {
    intro = payload.content.colPos1.map((item) => {
      return getModuleData(item)
    });
  }
  let main = [];
  if (payload.content.colPos0) {
    main = payload.content.colPos0.map((item) => {
      return getModuleData(item)
    });
  }

  return { intro, main }
}

const getHeaderSliderData = (payload) => {
  if (payload.type === "bwiapi_headerslider" && payload.content.data) {
    return {
      video: {
        video_src: payload.content.data[0].header_video,
        video_copyright: '@iStock.com/your_photo',
      },
      slides: payload.content.data
    }
  }
  return {
    video: '',
    slides: []
  }
}
const getModuleData = (module) => {
  if (module.type === 'structured_content') {
    const columns = extractStructuredContent(module.content.structure);
    return {
      type: module.type,
      props: { ...module.config, layout: module.content.structure.layout, columns },
    }
  }
  else {
    return {
      type: module.type,
      props: module.content.data
    }
  }
}
const extractStructuredContent = (structure) => {
  if (structure.rows.length === 0) {
    return {
      modules: []
    }
  }
  //we only have 1 and 2 col layouts, never more than 1 row
  return structure.rows[0].columns.map((col) => {
    //this is module list
    const elements = col.elements.map((el) => {
      return getModuleData(el)
    })
    return {
      modules: elements
    }
  })
}
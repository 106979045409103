import { Accordion } from "react-bootstrap";
import { AccordionToggle } from './AccordionToggle';
import { renderHtml } from '../../utils/RenderHtml';
import { useInView } from 'react-intersection-observer'
import { LayoutClasses } from '../LayoutClasses';
import { ImageCopyright } from "./ImageCopyright";


export function Person({ image, image_position, image_title, person_name, person_position, items, content, layout_classes, type }) {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div ref={ref} className={`person image--${image_position} ${inView ? '-in-view' : ''}`}>
      <div className="person__image__wrapper">
        <img className="person__image" src={image} alt={person_name} title={image_title} />
      </div>
      <div className="person__content__wrapper">
        <div className="person__content">
          {renderHtml(content)}
        </div>
        <div className="person__name">
          {person_name}
        </div>
        <div className="person__meta">
          {person_position}
        </div>
        {image_title  && (<ImageCopyright image_copyright={image_title}/>)}
        {items && (items.map((item, idx) => (
          <Accordion key={`accordion_${idx}`} defaultActiveKey="-1">
            <div className="accordion__item -align-left">
              <AccordionToggle eventKey={`${idx}`} togglePosition="left">
                {item.expandable_title}
              </AccordionToggle>
              <Accordion.Collapse timeout={1000} eventKey={`${idx}`} className="accordion__body">
                <div className="accordion__body__inner">{renderHtml(item.expandable_content)}</div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        )))}
      </div>
    </div>
  </LayoutClasses>
}
import SwiperCore, { Navigation, Pagination, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { renderHtml } from '../utils/RenderHtml';
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import gsap from 'gsap';
import { ImageCopyright } from './Modules/ImageCopyright';

const ANIMATION_TIME = 1500;
const HALF_ANIMATION_TIME_IN_S = (ANIMATION_TIME / 2 / 1000).toFixed(2);

SwiperCore.use([Navigation, Pagination, Parallax]);

export function KVSlider({ slider, onReadClick }) {
  // determines the correct slide by pathname an uses slideTo() to activate it
  const getSlideIndexByLocation = useCallback(
    (pathname) => {
      let index = 0;
      const locationRegExp = new RegExp(`${pathname}$`);
      for (const slide of slider.slides) {
        const match = slide.header_link.match(locationRegExp);
        if (match) {
          return index;
        }
        index++;
      }
    },
    [slider.slides]
  );

  // the location is needed to detect route changes and accordingly change the slide
  let location = useLocation();
  const startSlideIndex = getSlideIndexByLocation(location.pathname);
  let [lastLocationPath, setLastLocationPath] = useState(null);

  // a reference to swiper is needed to change the slides with slideTo
  const [swiper, setSwiper] = useState(null);

  // the history is needed to make router changes when "read" is clicked
  const history = useHistory();

  const initSwiper = (swiperInstance) => {
    setSwiper(swiperInstance);
    // reveal start slide only when index is 0 (for others slideChange is triggered)
    if (!startSlideIndex || startSlideIndex === 0) {
      const activeSlideFg = swiperInstance.slides[
        swiperInstance.activeIndex
      ].querySelector('.slide__fg');
      activeSlideFg.style.opacity = 1;
      gsap.to(activeSlideFg, {
        opacity: 0,
        duration: HALF_ANIMATION_TIME_IN_S,
        ease: 'power1.in',
        delay: HALF_ANIMATION_TIME_IN_S,
      });
    }
  };

  // initially set the correct slide after load
  useEffect(() => {
    if (swiper && location) {
      const index = getSlideIndexByLocation(location.pathname);
      swiper.slideTo(index);
    }
  }, [swiper, location, getSlideIndexByLocation]);

  // set the correct slide when the location changes, e.g. due to the nav
  useEffect(() => {
    // first, check if the location has changed at all
    let locationChanged = false;
    if (lastLocationPath !== location.pathname) {
      locationChanged = true;
      setLastLocationPath(location.pathname);
    }

    // no change -> do nothing
    if (!locationChanged) return;

    // no swiper -> do nothing
    if (!swiper) return;

    // set the swiper slide to the current routes link
    getSlideIndexByLocation(location.pathname);
  }, [
    swiper,
    location,
    lastLocationPath,
    getSlideIndexByLocation,
    slider.slides,
  ]);

  // when the slide changes, update the route
  const onSlideChange = (swiperInstance) => {
    const activeIndex = swiperInstance.activeIndex;
    const slide = slider.slides[activeIndex];
    const newRouteUrl = new URL(slide.header_link).pathname;
    history.push(newRouteUrl);

    // prev slide goes black
    const prevSlideFg = swiperInstance.slides[
      swiperInstance.previousIndex
    ].querySelector('.slide__fg');
    gsap.to(prevSlideFg, {
      opacity: 1,
      duration: HALF_ANIMATION_TIME_IN_S,
      ease: 'power1.out',
    });

    // active slide goes reveals
    const activeSlideFg = swiperInstance.slides[activeIndex].querySelector(
      '.slide__fg'
    );
    activeSlideFg.style.opacity = 1;
    gsap.to(activeSlideFg, {
      opacity: 0,
      duration: HALF_ANIMATION_TIME_IN_S,
      ease: 'power1.in',
      delay: HALF_ANIMATION_TIME_IN_S,
    });
  };

  return (
    <Swiper
      initialSlide={startSlideIndex || 0}
      slidesPerView={1}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      pagination={{ clickable: true, el: '.swiper-pagination' }}
      parallax
      onSlideChange={onSlideChange}
      onSwiper={initSwiper}
      threshold={20}
      speed={ANIMATION_TIME}
    >
      <div slot='container-start' className='swiper-video-container'>
        <div className='mood-video__wrapper'>
          <video muted loop autoPlay playsInline src={slider.video.video_src}></video>
        </div>
      </div>
      <span slot='container-end' className='swiper-navigation-container'>
        <Container>
          <Row>
            <Col>
              <button onClick={onReadClick} className='btn__read btn btn-dark'>
                <span>Kapitel lesen</span>
                <img src='/assets/icons/icon-arrow.svg' alt='' />
              </button>
            </Col>
          </Row>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              className='swiper-navigation__bar d-flex justify-content-between align-items-center'
            >
              <div className='swiper-button-prev'>
                <span className='swiper-button-label'>Vorheriges Kapitel</span>
              </div>
              <div className='swiper-pagination'></div>
              <div className='swiper-button-next'>
                <span className='swiper-button-label'>Nächstes Kapitel</span>
              </div>
            </Col>
          </Row>
        </Container>
      </span>

      {slider.slides.map((slide, i) => (
        <SwiperSlide className={`slide-${i}`} key={i}>
          <div className='slide__bg'>
            <img src={slide.header_background} alt='' title={slide.image_title} />
          </div>
          <ImageCopyright image_copyright={`${slide.image_title}; Animation: ${slider.video.video_copyright}`}/>
          <div className='slide__content__wrapper'>
            <Container>
              <Row>
                <Col>
                  <div className='slide__content'>
                    {slide.header_overline && (
                      <h3 className='slide__overline'>
                        {renderHtml(slide.header_overline)}
                      </h3>
                    )}
                    <h1 className='slide__headline'>
                      {renderHtml(slide.header_title)}
                    </h1>
                    <h2 className='slide__subline'>
                      {renderHtml(slide.header_subtitle)}
                    </h2>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className='slide__fg'></div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

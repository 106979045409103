import React from 'react';
import { useInView } from 'react-intersection-observer'
import { renderHtml } from '../../utils/RenderHtml';


export function ImageBubble(props) {

  const { item_image, image_title, item_text, item_title, idx } = props;

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return (
    <div className={`image-bubble ${inView ? '-in-view' : ''}`} ref={ref}>
      <div className={`image-bubble__image-wrapper`} >
        <img src={item_image} alt="" title={image_title} className="image-bubble__image" />
      </div>
      <div className="image-bubble__teaser">
        {item_title && (<div className="image-bubble__teaser__title">{renderHtml(item_title)}</div>)}
        <div className="image-bubble__teaser__text">{renderHtml(item_text)}</div>
      </div>
    </div>
  )
}
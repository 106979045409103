export function LayoutClasses({layout_classes, type, children}) {

  if (!layout_classes || !layout_classes.length) {
    layout_classes = 'col';
  }

  return <div className={`row row__${type}`}>
    <div className={`${layout_classes} col__${type}`}>
      {children}
    </div>
  </div>

}
import { useInView } from 'react-intersection-observer'
import { LayoutClasses } from '../LayoutClasses';

export function FlexImage({ image, image_alt, image_title, style, type, layout_classes }) {

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const classNames = [
    'flex-image',
    `flex-image--${style}`,
    inView ? '-in-view' : ''
  ];

  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div ref={ref} className={classNames.join(" ")}>
      <img src={image} alt={image_alt} title={image_title} />
    </div>
  </LayoutClasses>
}
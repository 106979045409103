import { renderHtml } from '../../utils/RenderHtml';
import { useInView } from 'react-intersection-observer'
import { LayoutClasses } from '../LayoutClasses';
import { ImageCopyright } from './ImageCopyright';

export function Interview({ id, layout_classes, type, items }) {
  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div className="interview">
      {items.map((item, i) => (
        <InterviewItem key={i} {...item} />
      ))}
    </div>
  </LayoutClasses>
}

export function InterviewItem({ interview_name, interview_text, interview_position, interview_image, image_title}) {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return <div ref={ref} className={`interview-item interview--${interview_position} ${inView ? '-in-view' : ''}`}>
    <div className="interview-item__content">
      <span className="interview-item__name">{interview_name}:</span>
      <div className="interview-item__answer">{renderHtml(interview_text)}</div>
    </div>
    <div className="interview-item__picture">
      <img src={interview_image} alt={interview_name} title={image_title} />
      {/* <ImageCopyright image_copyright={image_title}/> */}
    </div>
  </div>
}
import { renderHtml } from '../../utils/RenderHtml';
import { useInView } from 'react-intersection-observer'
import { LayoutClasses } from '../LayoutClasses';

export function Callout({ content, layout_classes, type }) {

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  return <LayoutClasses layout_classes={layout_classes} type={type}>
    <div ref={ref} className={`callout ${inView ? '-in-view' : ''}`}>
      < div className="callout__content" >
        {content && renderHtml(content)}
      </div >
    </div >
  </LayoutClasses>
}